var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "Modal",
        {
          attrs: {
            "overflow-auto": "",
            visible: _vm.visible,
            closable: "",
            title: "办理调动",
            size: "normal",
            "ok-text": "保存",
          },
          on: { ok: _vm.handleOk, cancel: _vm.handleCancel },
        },
        [
          _c(
            "a-form-model",
            {
              ref: "ruleForm",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-col": _vm.labelCol,
                "wrapper-col": _vm.wrapperCol,
              },
            },
            [
              _c(
                "a-form-model-item",
                { attrs: { label: "调动员工", prop: "staffId" } },
                [
                  _c("perTreeSelect", {
                    on: { getPerTreeValue: _vm.getPerTreeValue },
                    model: {
                      value: _vm.form.staffId,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "staffId", $$v)
                      },
                      expression: "form.staffId",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "调动类型", prop: "handleType" } },
                [
                  _c("dictionariesInput", {
                    attrs: { parameter: "transfer_type" },
                    model: {
                      value: _vm.form.handleType,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "handleType", $$v)
                      },
                      expression: "form.handleType",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "生效日期", prop: "applyTransferDate" } },
                [
                  _c("a-date-picker", {
                    attrs: {
                      "value-format": "YYYY-MM-DD",
                      placeholder: "请选择时间",
                    },
                    model: {
                      value: _vm.form.applyTransferDate,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "applyTransferDate", $$v)
                      },
                      expression: "form.applyTransferDate",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "div-line" },
                [
                  _c(
                    "a-form-model-item",
                    {
                      staticClass: "line-left",
                      attrs: {
                        label: "部门",
                        prop: "email",
                        "label-col": _vm.labelColC,
                        "wrapper-col": _vm.wrapperColC,
                      },
                    },
                    [
                      _c("depTreeSelect", {
                        attrs: {
                          multiple: false,
                          "allow-clear": false,
                          placeholder: "请选择部门",
                          disabled: "",
                        },
                        model: {
                          value: _vm.form.beferOrgId,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "beferOrgId", $$v)
                          },
                          expression: "form.beferOrgId",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      staticClass: "line-right",
                      attrs: {
                        label: "变更为",
                        prop: "afterOrgId",
                        "label-col": _vm.labelColC,
                        "wrapper-col": _vm.wrapperColC,
                      },
                    },
                    [
                      _c("depTreeSelect", {
                        attrs: {
                          multiple: false,
                          "allow-clear": false,
                          placeholder: "请选择部门",
                          "has-relation": true,
                        },
                        on: { getValueSelect: _vm.getValueSelect },
                        model: {
                          value: _vm.form.afterOrgId,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "afterOrgId", $$v)
                          },
                          expression: "form.afterOrgId",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "div-line" },
                [
                  _c(
                    "a-form-model-item",
                    {
                      staticClass: "line-left",
                      attrs: {
                        label: "岗位",
                        prop: "email",
                        "label-col": _vm.labelColC,
                        "wrapper-col": _vm.wrapperColC,
                      },
                    },
                    [
                      _c("postInput", {
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.form.beforePostId,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "beforePostId", $$v)
                          },
                          expression: "form.beforePostId",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      staticClass: "line-right",
                      attrs: {
                        label: "变更为",
                        prop: "afterPostId",
                        "label-col": _vm.labelColC,
                        "wrapper-col": _vm.wrapperColC,
                      },
                    },
                    [
                      _c("postInput", {
                        attrs: {
                          disabled: _vm.postDisabled,
                          clearable: false,
                          "org-id": _vm.orgIdObj,
                          "has-relation": true,
                        },
                        on: { getPostValue: _vm.getPostValue },
                        model: {
                          value: _vm.form.afterPostId,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "afterPostId", $$v)
                          },
                          expression: "form.afterPostId",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "div-line" },
                [
                  _c(
                    "a-form-model-item",
                    {
                      staticClass: "line-left",
                      attrs: {
                        label: "职务",
                        prop: "beforeDuty",
                        "label-col": _vm.labelColC,
                        "wrapper-col": _vm.wrapperColC,
                      },
                    },
                    [
                      _c("a-select", {
                        attrs: {
                          options: _vm.dutyList,
                          placeholder: "请选择",
                          disabled: "",
                          "allow-clear": "",
                        },
                        model: {
                          value: _vm.form.beforeDuty,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "beforeDuty", $$v)
                          },
                          expression: "form.beforeDuty",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      staticClass: "line-right",
                      attrs: {
                        label: "变更为",
                        prop: "afterDuty",
                        "label-col": _vm.labelColC,
                        "wrapper-col": _vm.wrapperColC,
                      },
                    },
                    [
                      _c("a-select", {
                        attrs: {
                          options: _vm.dutyList,
                          placeholder: "请选择",
                          disabled: _vm.postDisabled,
                          "allow-clear": "",
                        },
                        model: {
                          value: _vm.form.afterDuty,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "afterDuty", $$v)
                          },
                          expression: "form.afterDuty",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "div-line" },
                [
                  _c(
                    "a-form-model-item",
                    {
                      staticClass: "line-left",
                      attrs: {
                        label: "职级",
                        prop: "email",
                        "label-col": _vm.labelColC,
                        "wrapper-col": _vm.wrapperColC,
                      },
                    },
                    [
                      _c("rankInput", {
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.form.beforeJobGradeId,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "beforeJobGradeId", $$v)
                          },
                          expression: "form.beforeJobGradeId",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      staticClass: "line-right",
                      attrs: {
                        label: "变更为",
                        prop: "afterJobGradeId",
                        "label-col": _vm.labelColC,
                        "wrapper-col": _vm.wrapperColC,
                      },
                    },
                    [
                      _c("rankInput", {
                        attrs: {
                          clearable: false,
                          disabled: _vm.rankDisabled,
                          "has-relation": true,
                          "post-id": _vm.form.afterPostId,
                        },
                        model: {
                          value: _vm.form.afterJobGradeId,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "afterJobGradeId", $$v)
                          },
                          expression: "form.afterJobGradeId",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "div-line" },
                [
                  _c(
                    "a-form-model-item",
                    {
                      staticClass: "line-left",
                      attrs: {
                        label: "工作地点",
                        prop: "email",
                        "label-col": _vm.labelColC,
                        "wrapper-col": _vm.wrapperColC,
                      },
                    },
                    [
                      _c("a-select", {
                        attrs: {
                          placeholder: "请选择",
                          options: _vm.workAddressList,
                          disabled: "",
                        },
                        model: {
                          value: _vm.form.beforeWorkplaceId,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "beforeWorkplaceId", $$v)
                          },
                          expression: "form.beforeWorkplaceId",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      staticClass: "line-right",
                      attrs: {
                        label: "变更为",
                        prop: "afterWorkplaceId",
                        "label-col": _vm.labelColC,
                        "wrapper-col": _vm.wrapperColC,
                      },
                    },
                    [
                      _c("a-select", {
                        attrs: {
                          placeholder: "请选择",
                          options: _vm.workAddressList,
                        },
                        model: {
                          value: _vm.form.afterWorkplaceId,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "afterWorkplaceId", $$v)
                          },
                          expression: "form.afterWorkplaceId",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "div-line" },
                [
                  _c(
                    "a-form-model-item",
                    {
                      staticClass: "line-left",
                      attrs: {
                        label: "合同公司",
                        prop: "email",
                        "label-col": _vm.labelColC,
                        "wrapper-col": _vm.wrapperColC,
                      },
                    },
                    [
                      _c("a-select", {
                        attrs: {
                          placeholder: "请选择",
                          options: _vm.contractCompanyList,
                          disabled: "",
                        },
                        model: {
                          value: _vm.form.beforeContractCompanyId,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "beforeContractCompanyId", $$v)
                          },
                          expression: "form.beforeContractCompanyId",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      staticClass: "line-right",
                      attrs: {
                        label: "变更为",
                        prop: "afterContractCompanyId",
                        "label-col": _vm.labelColC,
                        "wrapper-col": _vm.wrapperColC,
                      },
                    },
                    [
                      _c("a-select", {
                        attrs: {
                          placeholder: "请选择",
                          options: _vm.contractCompanyList,
                        },
                        model: {
                          value: _vm.form.afterContractCompanyId,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "afterContractCompanyId", $$v)
                          },
                          expression: "form.afterContractCompanyId",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "调动原因", prop: "reasons" } },
                [
                  _c("a-input", {
                    attrs: {
                      type: "textarea",
                      "auto-size": true,
                      placeholder: "请输入",
                    },
                    model: {
                      value: _vm.form.reasons,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "reasons", $$v)
                      },
                      expression: "form.reasons",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }